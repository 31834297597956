import React, {Fragment, Component} from 'react';

class SignatureForm extends Component {

  render() {
    return (
      <Fragment>
        <form className="">
          <h3>Infos personnelles</h3>
          <div className="fields">
            <div className="d-flex flex-column">

              <label htmlFor="firstname">Prénom</label>

              <input
                onChange={(e) => this.props.handleChange(e, 'prenom')}
                value={this.props.prenom}
                className="firstname"
                type="text"
                name="firstname"/>

              <label htmlFor="lastname">NOM</label>

              <input
                onChange={(e) => this.props.handleChange(e, 'nom')}
                value={this.props.nom}
                className="lastname"
                type="text"
                name="lastname"/>

              <label htmlFor="poste">Poste</label>
              <input
                onChange={(e) => this.props.handleChange(e, 'poste')}
                value={this.props.poste}
                className="poste"
                type="text"
                name="poste"/>

              <label htmlFor="mobile">Téléphone</label>
              <input
                onChange={(e) => this.props.handleChange(e, 'mobile')}
                value={this.props.mobile}
                className="mobile"
                type="text"
                name="mobile"
              />

              <label htmlFor="mail">Adresse</label>
              <input
                onChange={(e) => this.props.handleChange(e, 'adresse')}
                value={this.props.adresse}
                className="mail"
                type="mail"
                name="mail"
              />

              <label htmlFor="mail">Calendrier</label>
              <input
                onChange={(e) => this.props.handleChange(e, 'calendar')}
                value={this.props.calendar}
                className="calendar"
                type="url"
                name="calendar"
              />

            </div>
          </div>
        </form>
      </Fragment>
    );
  }
};

export default SignatureForm;