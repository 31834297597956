import 'bootstrap/dist/css/bootstrap.min.css'
import firebase from "firebase";
import 'firebase/storage'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//Config firebase
const firebaseConfig = {

  apiKey: "AIzaSyAfMn2ydPtixxRaudmC9NLO6ee9FOXvj34",
  authDomain: "signature-enoki.firebaseapp.com",
  databaseURL: "https://signature-enoki-default-rtdb.firebaseio.com",
  projectId: "signature-enoki",
  storageBucket: "signature-enoki.appspot.com",
  messagingSenderId: "907070231326",
  appId: "1:907070231326:web:01f72ae71a1f0b534faf0f"

}

firebase.initializeApp(firebaseConfig)

const storage = firebase.storage()


ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export {
  storage, firebase as default
}
