import React, {Fragment} from 'react';
import logo from "../../enokistudiologo.svg"


const SignatureEnoki = ({
                            prenom,
                            nom,
                            poste,
                            mobile,
                            adresse,
                            calendar,
                        }) => {

    return (
        <Fragment>
            <html xmlns="http://www.w3.org/1999/xhtml" id={'AA'}>
            <head>
                <title>Signature Enoki</title>
                <style type="text/css" id="stylesign">
                    @import url('https://use.typekit.net/hly2yus.css')
                </style>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
                <link
                    href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                    rel="stylesheet"/>
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
                    integrity="sha512-+4zCK9k+qNFUR5X+cKL9EIR+ZOhtIloNl9GIKS57V1MyNsYpYcUrUeQc9vNfzsWfV28IaLL3i96P9sdNyeRssA=="
                    crossOrigin="anonymous"/>
            </head>
            <body>
            <table style={{width: "373px"}}>
                <tbody>
                <tr>
                    <td style={{width: "100px"}}>
                        <a href={'https://www.enoki-studio.com'}><img className="logo-sign"
                                                                      style={{
                                                                          background: ' no-repeat center',
                                                                          backgroundSize: 'cover',
                                                                          width: "131px",
                                                                          paddingRight: "5px",
                                                                          height: "auto",
                                                                      }}
                                                                      src={"https://firebasestorage.googleapis.com/v0/b/signature-2d394.appspot.com/o/Group%201089.png?alt=media&token=0187c41c-c370-42de-9e60-116c391548c1"}
                                                                      alt="enoki-logo"/></a>
                    </td>
                    <td style={{width: "138px", verticalAlign: "baseline", padding: 'unset'}}>
                        <table style={{color: "#033859", width: "230px"}}>
                            <tr>
                                <td style={{
                                    fontFamily: 'Arial, sans-serif',
                                    fontWeight: "900",
                                    fontSize: "16px",
                                    paddingBottom: "1px",
                                }}>
                                    {prenom} {nom}
                                </td>
                            </tr>
                            <tr>
                                <td style={{
                                    fontFamily: 'Arial, sans-serif',
                                    fontWeight: "800",
                                    fontSize: "14px",
                                    paddingBottom: "5px",
                                }}>
                                    {poste}
                                </td>
                            </tr>
                            <tr>
                                <td style={{
                                    fontFamily: 'Arial, sans-serif',
                                    paddingBottom: "5px",
                                    fontWeight: "400",
                                    fontSize: '12px',
                                    color: "#666570",
                                }}>
                                    {mobile}
                                </td>
                            </tr>
                            <tr>
                                <td style={{
                                    fontFamily: 'Arial, sans-serif',
                                    paddingBottom: "5px",
                                    fontWeight: "400",
                                    fontSize: '12px',
                                    color: "#666570",
                                }}>
                                    {adresse}
                                </td>
                            </tr>
                            {
                                calendar ?
                                    <tr>
                                        <td style={{
                                            fontFamily: 'Arial, sans-serif',
                                            lineHeight: "6pt",
                                            fontSize: 12,
                                            fontWeight: "500",
                                            paddingTop: '7px',
                                            color: "#233261",
                                        }}>
                                            <a style={{
                                                fontFamily: 'Arial, sans-serif',
                                                lineHeight: "6pt",
                                                fontSize: 12,
                                                height: "11px",
                                                fontWeight: "600",
                                                color: "#233261",
                                            }}
                                               href={calendar}>{'Prendre rendez-vous ici 👉 🗓'}</a>
                                        </td>
                                    </tr>
                                    :
                                    <tr>
                                        <td style={{
                                            fontFamily: 'Arial, sans-serif',
                                            lineHeight: "6pt",
                                            fontSize: 12,
                                            fontWeight: "500",
                                            paddingTop: '7px',
                                            color: "#233261",
                                        }}>
                                            <a style={{
                                                fontFamily: 'Arial, sans-serif',
                                                lineHeight: "6pt",
                                                fontSize: 12,
                                                height: "11px",
                                                fontWeight: "600",
                                                color: "#233261",
                                            }}
                                               href={'https://www.enoki-studio.com'}>{'www.enoki-studio.com'}</a>
                                        </td>
                                    </tr>
                            }
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} style={calendar ? {width: "470px", paddingTop: "10px", paddingBottom: "6px"} : {
                        width: "470px",
                        paddingTop: "10px",
                        paddingBottom: "10px"
                    }}>
                        <div style={{backgroundColor: "#8C8B9A", width: '100%', height: '1px'}}></div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} style={{
                        width: "470px",
                        fontWeight: "400",
                        color: "#666570",
                        fontSize: '11px',
                        fontFamily: 'Arial, sans-serif',
                        fontStyle: 'italic'
                    }}>
                        {"Pour la planète pensez à supprimer cet email une fois qu’il ne vous est plus utile 🌱"}
                    </td>
                </tr>
                </tbody>
            </table>
            <table>

            </table>
            </body>
            </html>
        </Fragment>
    );
};

export default SignatureEnoki;