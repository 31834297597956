import React, {Component} from 'react';
import firebase from "firebase";

// CSS
import './App.css';

// Formulaire
import SignatureForm from "./components/SignatureForm";

// Templates
import SignatureEnoki from "./components/signatures/SignatureEnoki";


class App extends Component {

  state = {

    file: null,

    // Templates
    template: "AA",
    prenom: "Prénom",
    nom: "Nom",
    poste: "poste",
    mobile: "+33 6 48 14 73 78",
    adresse: '78 avenue de la répubique, 75011 Paris',
    calendar: null,

    // CopyToClipboard component
    value: '',
    copied: false,
    image: '',
    imageURL: '',
    progress: 0,
  }

  // static images on start
  linkPlaceholderAA = 'https://www.enoki-studio.com/staticfiles/assets/logos/logo_enoki.d8f71378b438.png'

  handleUploadStart = () => {
    this.setState({
      progress: 0
    })
  }

  handleUploadSuccess = filename => {
    this.setState({
      image: filename,
      progress: 100,

    })
    firebase.storage().ref('avatar').child(filename).getDownloadURL()
      .then(url => this.setState({
        file: url,

      }))

  }
  copyTable = (template) => {
    const elTable = document.querySelector(`#${template}`);
    let range, sel;

    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {

      range = document.createRange();
      sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }
      document.execCommand('copy');
    }
    sel.removeAllRanges();

    console.log('Element Copied! Paste it in a file')
  }

  handleChange = (event, propName) => {
    let text = event.target.value
    this.setState({[propName]: text})
  }


  render() {
    let { prenom, nom, poste, mobile, file, adresse, calendar} = this.state

    return (
      <div id="d-flex container-global left" className={"container"}>
        <div className="row">

          <div className="col-md-6 col-12 signature__form">
            <SignatureForm

              file={file}
              prenom={prenom}
              nom={nom}
              poste={poste}
              mobile={mobile}
              adresse={adresse}
              calendar={calendar}
              handleChange={this.handleChange}
              handleOptionChange={this.handleOptionChange}
              handleUploadStart={this.handleUploadStart}
              handleUploadSuccess={this.handleUploadSuccess}
            />
          </div>
          <div
            className="signature__output col-md-6 col-12 mt-sm-2">

              <SignatureEnoki

                file={file ? file : this.linkPlaceholderAA}
                prenom={prenom}
                nom={nom}
                poste={poste}
                mobile={mobile}
                adresse={adresse}
                calendar={calendar}
              />

            <button className='mt-5 copy btn btn-outline-primary'
                    type={"button"}
                    onClick={()=>this.copyTable(this.state.template)}>
              Copier dans le presse-papier
            </button>
          </div>
        </div>
      </div>

    );
  }
}

export default App;

